import { NavButton } from './NavButton'
import { SpeakIcon, SpeakActiveIcon } from 'icons'

const SpeakButton: React.FC<{ id: string; disabled?: boolean; handleOnClick?: (data: string) => void }> = ({ id, disabled, handleOnClick }) => {
    return (
        <NavButton
            id={id}
            text='Speak'
            path='/teacher/classroom'
            icon={SpeakIcon}
            activeIcon={SpeakActiveIcon}
            disabled={disabled}
            onClick={() => {
                if (handleOnClick) {
                    handleOnClick('/teacher/classroom')
                }
            }}
        />
    )
}

export { SpeakButton }
