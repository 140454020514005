import { useContext, useEffect, useState } from 'react'
import { ConferenceContext } from 'contexts/conference'
import { WaitingForOthers } from 'components/status-panels'
import { BorZoomLayout, ZoomLayout } from 'components/participants'
import { ViewCueCardsModal } from './components/ViewCueCardsModal'
import { ShareCueCardsModal } from './components'
import { BreakoutRoomsState } from 'pages/useBreakouts'

const CueCardsView: React.FC<{ breakouts?: BreakoutRoomsState }> = ({ breakouts }) => {
    const [cueCardModalZoomed, setCueCardModalZoomed] = useState<boolean>(false)
    const [viewShareCueCardModal, setShareCueCardModalVisibility] = useState<boolean>(false)
    const [zoomedCueCardImage, setZoomedCueCardImage] = useState<string>()
    const conference = useContext(ConferenceContext)

    useEffect(() => {
        if (conference.status === 'session-joined') {
            conference.primus.send('change-screen', { screen: 'cue-card' })
        }
    }, [])

    const getZoomedCueCard = (imageUrl: string): void => {
        setCueCardModalZoomed(true)
        setZoomedCueCardImage(imageUrl)
    }

    return (
        <>
            <WaitingForOthers />
            {breakouts?.isActive
                ? <BorZoomLayout breakouts={breakouts} isCueCard />
                : <ZoomLayout
                    isCueCards={true}
                    cueCardZoomModal={data => getZoomedCueCard(data)}
                    showCueCardSharingModal={() => setShareCueCardModalVisibility(true)}
                />
            }

            {(cueCardModalZoomed && zoomedCueCardImage) ? (
                <ViewCueCardsModal
                    imageURL={zoomedCueCardImage}
                    close={() => setCueCardModalZoomed(false)} />
            ) : null}

            {viewShareCueCardModal ? (
                <ShareCueCardsModal close={() => setShareCueCardModalVisibility(false)} />
            ) : null}
        </>
    )
}

export { CueCardsView }
