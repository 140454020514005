import { styled } from '@mui/material'
import { ReactSortable } from 'react-sortablejs'
import { DragIcon } from 'icons'
import { StepButton } from './Buttons'
import { RoomAssignment } from './BreakoutRoomsModal'
import { getDisplayName, getSameOriginPhotoUrl } from 'utils/get-display-name'

const RoomModule = styled('div')(({ theme }) => ({
    borderRadius: theme.spacing(2),
    border: `solid 1px ${theme.palette.neutral.hover}`,
    overflow: 'hidden',
    margin: `${theme.spacing(2)} 0`,
    p: {
        width: '100%',
        backgroundColor: theme.palette.neutral.darker,
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        fontWeight: '600',
        span: {
            color: theme.palette.error.main,
            fontSize: theme.spacing(1.75),
            fontWeight: 400,
            marginLeft: theme.spacing(2)
        }
    },
    '> div': {
        padding: theme.spacing(2)
    }
}))
const Student = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.neutral.alternate,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    img: {
        cursor: 'grab'
    },
    '> span': {
        fontWeight: '600'
    },
    '&:last-of-type': {
        margin: 0
    }
}))
const Avatar = styled('div')(({ theme }) => ({
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(1.5),
    fontWeight: 800,
    width: theme.spacing(3),
    aspectRatio: '1',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.35)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: theme.palette.neutral.main,
    margin: `0 ${theme.spacing(1)}`
}))
const StepButtonsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    button: {
        marginLeft: theme.spacing(1)
    }
}))

type SettingsStepProps = {
    roomsCount: number
    getRoom: (index: number) => RoomAssignment[]
    setRooms: (index: number, students: RoomAssignment[]) => void
    startBor: () => void
    moveStep: () => void
    roomsEmpty: boolean
    roomsFull: boolean
    forCC: boolean
}

const RoomsModalStep: React.FC<SettingsStepProps> = ({ roomsCount, getRoom, setRooms, startBor, moveStep, roomsEmpty, roomsFull, forCC = false }) => (
    <>
        <div id='bor-modal-rooms-wrapper'>
            {Array.from(Array(roomsCount)).map((_, i) => (
                <RoomModule id='bor-modal-room-module' key={`Room ${String.fromCharCode('A'.charCodeAt(0) + i)}`}>
                    <p>
                        {`Room ${String.fromCharCode('A'.charCodeAt(0) + i)}`}
                        {getRoom(i).length > 4 ? (
                            <span>Room cannot have more than 4 students</span>
                        ) : null}
                    </p>
                    <div>
                        <ReactSortable
                            id='bor-modal-rooms-container'
                            list={getRoom(i)}
                            setList={data => setRooms(i, data)}
                            animation={200}
                            group='shared'
                            handle='.dragHandle'
                        >
                            {getRoom(i).map((s: RoomAssignment) => (
                                <Student id='bor-modal-student-drag' key={s.id}>
                                    <img src={DragIcon} className='dragHandle' />
                                    <Avatar id='bor-modal-avatar' sx={{
                                        backgroundImage: s.wseProfilePhotoUrl
                                            ? `url('${getSameOriginPhotoUrl(s.wseProfilePhotoUrl)}')` : 'none'
                                    }}>
                                        {!s.wseProfilePhotoUrl ? (
                                            <span>{getDisplayName(s, 'initials')}</span>
                                        ) : null}
                                    </Avatar>
                                    <span>{getDisplayName(s, 'student-name')}</span>
                                </Student>
                            ))}
                        </ReactSortable>
                    </div>
                </RoomModule>
            ))}
        </div>
        <StepButtonsWrapper>
            <StepButton id='bor-modal-create-rooms-button' text={forCC ? 'Create Breakout Rooms' : 'Create Rooms'} onClick={forCC ? moveStep : startBor} />
        </StepButtonsWrapper>
    </>
)

export { RoomsModalStep }
