import { Button, ButtonProps } from '@mui/material'
import { Icon, IconProps } from 'components/icon-buttons'

type RectangleButtonProps = {
    id?: string
    color: ButtonProps['color']
    disabled?: boolean
    text: string
    variant?: 'contained' | 'outlined'
    onClick?: ButtonProps['onClick']
    iconSrc?: IconProps['src']
    iconFrontSrc?: IconProps['src']
    height?: string
    onMouseDown?: ButtonProps['onMouseDown']
    customColor?: string
}

const RectangleButton: React.FC<RectangleButtonProps> =
({ id, text, variant, color, onClick, disabled, iconFrontSrc = null, iconSrc = null, height = null, onMouseDown, customColor = null }) => (
    <Button
        id={id}
        onClick={onClick}
        variant={variant ?? 'contained'}
        disabled={disabled}
        onMouseDown={onMouseDown}
        color={color}
        sx={theme => ({
            borderRadius: theme.spacing(0.5),
            fontWeight: 600,
            fontSize: theme.spacing(2),
            lineHeight: '140%',
            letterSpacing: '0.02em',
            height: height || theme.spacing(6),
            paddingRight: iconSrc && '8px',
            minWidth: '56px',
            '> img': {
                marginLeft: iconSrc ? '8px' : 'unset',
                marginRight: iconFrontSrc ? '8px' : 'unset'
            },
            background: customColor ? 'rgba(255, 255, 255, 0.65)' : null,
            border: customColor ? `2px solid ${customColor} !important` : '1px !important',
            color: customColor || null,
            '&:hover': {
                backgroundColor: customColor ? 'rgba(241, 44, 62, 0.05)' : null
            }
        })}
    >
        {iconFrontSrc ? <Icon src={iconFrontSrc} size='medium' /> : null}
        {text}
        {iconSrc ? <Icon src={iconSrc} size='medium' /> : null}
    </Button>
)

export { RectangleButton }
