import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Action, CustomDialogComponent } from 'hello-design-system'

export const DialogComponent = styled(CustomDialogComponent)(() => (
    {
        '& .MuiDialog-paper': {
            maxWidth: '600px !important'
        },
        '& .MuiTypography-h6': {
            textTransform: 'unset !important',
            fontWeight: 700,
            fontSize: '18px'
        },
        '.MuiButton-containedPrimary': {
            borderRadius: '16px !important'
        },
        '.MuiButton-outlinedPrimary': {
            color: '#003359 !important',
            fontWeight: 700,
            fontSize: '15px'
        }
    }
))

export const StyledBox = styled(Box)({
    display: 'inline',
    fontWeight: 'bold'
})

export const StyledTypography = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    fontSize: '12px'
}))

const ShowCueCardBRSuggestion: React.FC<{ close: () => void; continueCC: () => void; startBRSetup: () => void }> = ({ close, continueCC, startBRSetup }) => {
    const actions: Action[] = [{
        label: 'Share Cue Cards in speak mode',
        onClick: () => continueCC(),
        variant: 'outlined',
        color: 'primary'
    }, {
        label: 'Setup Breakout Rooms',
        onClick: () => startBRSetup(),
        variant: 'contained',
        color: 'primary'
    }]

    return (
        <>
            <DialogComponent
                open={true}
                onClose={() => close()}
                title='Share Cue Cards in Breakout Rooms'
                actions={actions}
                maxWidth={'sm'}
                showCloseIcon={true}
                fullScreen={false}
            >
                <StyledBox>
                    <StyledTypography
                        color='text.primary'
                        gutterBottom
                    >
                        Since there are more than three students in the class, it is recommended to share the Cue Card in Breakout Rooms.
                    </StyledTypography>
                </StyledBox>
            </DialogComponent>
        </>
    )
}

export { ShowCueCardBRSuggestion }
