import { NavButton } from './NavButton'
import { SlidesIcon, SlidesActiveIcon } from 'icons'

const SlidesButton: React.FC<{ id: string; disabled?: boolean; handleOnClick?: (data: string) => void }> = ({ id, disabled, handleOnClick }) => (
    <NavButton
        id={id}
        path='/teacher/classroom/slides'
        text='Slides'
        icon={SlidesIcon}
        activeIcon={SlidesActiveIcon}
        disabled={disabled}
        onClick={() => {
            if (handleOnClick) {
                handleOnClick('/teacher/classroom/slides')
            }
        }}
    />
)

export { SlidesButton }
