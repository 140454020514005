import { useContext, useEffect, useState } from 'react'
import { ButtonGroup, styled } from '@mui/material'
import { ConferenceContext } from 'contexts/conference'
import { ConversationType, MessageType } from 'chat'
import { MessagePopup } from 'components/chat'
import { Selfie } from 'components/participants'
import { SidebarProps, SidebarComponentProps } from 'components/sidebar'
import { ButtonWrapper } from 'components/buttons'
import { AssessmentIconButton, NotesIconButton, ChatIconButton } from 'components/icon-buttons'
import { BreakoutRoomsState } from 'pages/useBreakouts'
import { SpeakButton, SlidesButton, WhiteboardButton, CueCardsButton } from './nav-buttons'
import { getDisplayName } from 'utils/get-display-name'
import { useLocation } from 'react-router-dom'

const Wrapper = styled('div')(({ theme }) => ({
    padding: `${theme.spacing(16)} var(--page-side-margin) var(--page-v-margin)`,
    boxSizing: 'content-box',
    display: 'flex',
    gap: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
        padding: `${theme.spacing(20)} var(--page-side-margin) var(--page-v-margin)`,
        flexDirection: 'column'
    }
}))
const LeftWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    flex: 1,
    justifyContent: 'left',
    alignItems: 'flex-end',
    minWidth: theme.spacing(30),
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
    }
}))
const CenterWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    marginLeft: theme.spacing(1)
}))
const RightWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    flex: 1,
    justifyContent: 'right',
    [theme.breakpoints.down('md')]: {
        justifyContent: 'center'
    }
}))
const SelfieWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    border: `solid ${theme.spacing(1)} white`,
    borderRadius: theme.spacing(2.5),
    overflow: 'hidden',
    boxSizing: 'content-box'
}))

interface BottomControlsAdditionalProps {
    breakouts?: BreakoutRoomsState
    showAbandonCueCardModal?: (data: string) => Promise<void>
    showCueCardModal?: () => Promise<void>
}

const BottomControls: React.FC<SidebarComponentProps & BottomControlsAdditionalProps> = ({
    breakouts, currentSidebar, setCurrentSidebar, showCueCardModal, showAbandonCueCardModal
}) => {
    const conference = useContext(ConferenceContext)
    const location = useLocation()

    const sessionJoined = conference.status === 'session-joined'
    const isNotesIconDisabled = location.pathname !== '/teacher/classroom/slides'

    const toggleSidebar = (currentState: SidebarProps): void => {
        setCurrentSidebar(prevState => prevState.type === currentState.type ? { type: null } : currentState)
    }
    const isAssessmentDisabled = !sessionJoined || (sessionJoined && conference.classroomStateOnJoin.classTypeName !== 'Online Encounter')

    const studentList = conference.participants.find(participant => participant.role !== 'teacher')
    const isCueCardButtonVisible = sessionJoined && conference?.cueCardInfo
    const isCueCardButtonDisable = !sessionJoined || !studentList
    // const isCueCardButtonDisable = false

    const [lastMessage, setLastMessage] = useState<{ from: string; text: string; conversation: ConversationType } | null>(null)
    const [notificationsCount, setNotificationsCount] = useState<number>(0)
    const onMessageClick = (): void => {
        setCurrentSidebar({ type: 'chat', conversation: lastMessage?.conversation })
        setLastMessage(null)
    }
    const onMessageCloseClick = (e: React.MouseEvent<HTMLElement>): void => {
        e.stopPropagation()
        setLastMessage(null)
    }
    const onChatClick = (): void => {
        toggleSidebar({ type: 'chat' })
        setLastMessage(null)
    }

    useEffect(() => {
        if (isNotesIconDisabled && currentSidebar.type === 'notes') {
            setCurrentSidebar({ type: null })
        }
    }, [isNotesIconDisabled])

    useEffect(() => {
        if (sessionJoined) {
            setNotificationsCount(conference.chatClient.getTotalNotifications())

            const notificationHandler = (): void => {
                setNotificationsCount(conference.chatClient.getTotalNotifications())
            }
            const messageHandler = (message: MessageType, conversation: ConversationType): void => {
                if (conversation.chatId !== 'EVERYONE' && !message.isOwnMessage) {
                    setLastMessage({
                        from: getDisplayName(conversation, 'student-name'),
                        text: message.text,
                        conversation
                    })
                }
            }

            conference.chatClient.addNotificationHandler(notificationHandler)
            conference.chatClient.addChatMessageHandler(messageHandler)

            return () => {
                conference.chatClient.removeNotificationHandler(notificationHandler)
                conference.chatClient.removeChatMessageHandler(messageHandler)
            }
        }
    }, [conference.status])

    return (
        <Wrapper>
            <LeftWrapper>
                <SelfieWrapper>
                    <Selfie sidebarOpen={!!currentSidebar.type} />
                </SelfieWrapper>
            </LeftWrapper>
            <CenterWrapper>
                <ButtonGroup>
                    <SpeakButton id='teacher-speak-button' disabled={!sessionJoined} handleOnClick={showAbandonCueCardModal} />
                    {isCueCardButtonVisible && <CueCardsButton id='teacher-cuecard-button' disabled={isCueCardButtonDisable} onClick={showCueCardModal} /> }
                    <SlidesButton id='teacher-slides-button' disabled={!sessionJoined} handleOnClick={showAbandonCueCardModal} />
                    <WhiteboardButton id='teacher-whiteboard-button' disabled={!sessionJoined} handleOnClick={showAbandonCueCardModal} />
                </ButtonGroup>
            </CenterWrapper>
            <RightWrapper>
                <ButtonWrapper>
                    <ChatIconButton id='teacher-chat-button'
                        active={currentSidebar.type === 'chat'}
                        onClick={onChatClick}
                        disabled={!sessionJoined}
                        notifications={notificationsCount}
                    />
                    {lastMessage && sessionJoined ? (
                        <MessagePopup lastMessage={lastMessage} onMessageClick={onMessageClick} onCloseClick={onMessageCloseClick} />
                    ) : null}
                </ButtonWrapper>
                <ButtonWrapper>
                    <NotesIconButton id='teacher-noted-button'
                        active={currentSidebar.type === 'notes'}
                        onClick={() => toggleSidebar({ type: 'notes' })}
                        disabled={!sessionJoined || isNotesIconDisabled}
                    />
                </ButtonWrapper>
                <ButtonWrapper>
                    <AssessmentIconButton id='teacher-assessment-button'
                        active={currentSidebar.type === 'assessment'}
                        onClick={() => toggleSidebar({ type: 'assessment' })}
                        disabled={isAssessmentDisabled}
                    />
                </ButtonWrapper>
            </RightWrapper>
        </Wrapper>
    )
}

export { BottomControls }
