import { useContext, useState } from 'react'

import { FormControl, ListSubheader, MenuItem, styled } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { CueCardInfo } from 'api/classroom/ClassroomState'

import { ConferenceContext } from 'contexts/conference'
import { BreakoutRoomsState } from 'pages/useBreakouts'

import { getDisplayName } from 'utils/get-display-name'
import { numberToAlphabet } from './CueCardsModal'

import { CueCardItemArray, ParticipantData } from './ShareCueCardsModal'

import dropDownIcon from '../dropDownIcon.svg'

const SelectContainer = styled('div')(() => ({
    maxHeight: '460px',
    overflow: 'auto',
    width: '530px',
    '> div': {
        margin: '8px 0 !important',
        display: 'flex',
        justifyContent: 'space-between'
    }
}))

const Avatar = styled('div')(({ theme }) => ({
    fontSize: theme.spacing(1.5),
    lineHeight: theme.spacing(1.5),
    fontWeight: 800,
    width: theme.spacing(3),
    height: theme.spacing(3),
    aspectRatio: '1',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.35)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: theme.palette.neutral.main,
    margin: `0 ${theme.spacing(1)}`
}))

const StudentItem = styled('div')<{ breakoutActive: boolean }>(({ theme, breakoutActive }) => ({
    textAlign: 'left',
    width: breakoutActive ? '150px !important' : '424px !important',
    fontSize: '16px',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent !important'
}))

const DropdownIcon = styled('img')(() => ({
    right: '10px',
    position: 'absolute',
    pointerEvents: 'none' // added to cover overall div click
}))

const CustomMenuItem = styled(MenuItem)(() => ({
    width: '372px',
    margin: '0px',
    padding: '8px 8px 8px 24px',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 'normal',
    fontFamily: 'Noto Sans',
    lineHeight: 'unset !important',
    '&:hover': {
        width: '100%'
    }
}))

const StudentSelectContainer = styled('div')<{ breakoutActive: boolean }>(({ breakoutActive, theme }) => ({
    width: breakoutActive ? '346px !important' : 'unset',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(breakoutActive ? 0 : 3),
    marginTop: theme.spacing(breakoutActive ? 0 : 1)
}))

const SlideItem = styled('img')<{ breakoutActive: boolean }>(({ breakoutActive }) => ({
    width: breakoutActive ? '66px' : '86px',
    height: '48px'
}))

const CueCardSelect: React.FC<{
    studentNameList: ParticipantData[]
    handleChange: (event: SelectChangeEvent<''>, participant: ParticipantData) => void
    selectedCueCards: CueCardItemArray
    cueCardResponse: false | CueCardInfo[] | null
    clearCueCard: (participant: ParticipantData) => void
    showCueCardImage: (participantId: string) => string
    breakout?: BreakoutRoomsState
    breakoutActive: boolean
}> = ({ studentNameList, breakout, handleChange, selectedCueCards, cueCardResponse, clearCueCard, showCueCardImage, breakoutActive }) => {
    const conference = useContext(ConferenceContext)

    const [selectedStudentId, setSelectedStudentId] = useState<string>('')

    const checkMediaAvailability = (contentId: string): boolean => {
        const activeRoomUsers = breakout?.rooms?.find(br => br.users.includes(selectedStudentId))?.users
        const activeRoomSelectedCueCards = selectedCueCards.filter(data => activeRoomUsers?.includes(data.id)).map(cc => cc.cueCard?.contentId)
        return !activeRoomSelectedCueCards.includes(contentId)
    }

    const handleOnClick = (par: ParticipantData): any => setSelectedStudentId(par.id)

    const getParticipantDP = (id: string): string => conference.participants.find(data => data.wseUserId === id)?.wseProfilePhotoUrl || ''

    return (
        <>
            <SelectContainer>
                {studentNameList.map((participant, participantIndex) => (
                    <FormControl key={participantIndex} sx={{
                        display: 'flex',
                        position: 'relative',
                        flexDirection: breakoutActive ? 'row' : 'column',
                        width: breakoutActive ? '520px' : '519px',
                        margin: '0px 0px 12px 0px'
                    }}>
                        <StudentItem breakoutActive={breakoutActive}>
                            {breakoutActive ? <Avatar id='bor-modal-avatar' sx={{
                                backgroundImage: getParticipantDP(participant.id)
                                    ? `url('${getParticipantDP(participant.id)}')` : 'none'
                            }}>
                                {!getParticipantDP(participant.id) ? (
                                    <span style={{
                                        marginLeft: 0,
                                        fontSize: '10px'
                                    }}>{getDisplayName({ firstName: participant.firstName, lastName: participant.lastName }, 'initials')}</span>
                                ) : null}
                            </Avatar> : null}
                            {participant.firstName + ` ${participant.lastName ? participant.lastName : ''}` }
                        </StudentItem>
                        <StudentSelectContainer breakoutActive={breakoutActive}>
                            <Select
                                IconComponent={() => (
                                    <DropdownIcon src={dropDownIcon} alt='DropdownIcon' />
                                )}
                                className='Textbox'
                                name='option'
                                sx={() => ({
                                    borderRadius: '0px',
                                    width: breakoutActive ? '256px' : '424px',
                                    height: '48px',
                                    padding: '10px 12px',
                                    border: '2px solid rgba(0, 0, 0, 0.14)',
                                    margin: '0px',
                                    '& .MuiSelect-select': {
                                        padding: '0px !important'
                                    },
                                    '& .MuiSelect-icon': {
                                        fill: 'none !important'
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderStyle: 'none !important'
                                    }
                                })}
                                value={''} // value att needed to pass the event for onChange
                                id={`grouped-select-${participantIndex}`}
                                data-testid={`grouped-select-${participantIndex}`}
                                onChange={(ev: SelectChangeEvent<''>) => handleChange(ev, participant)}
                                onOpen={() => handleOnClick(participant)}
                                onClose={() => setSelectedStudentId('')}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                renderValue={() => {
                                    const selectedStudent = selectedCueCards.find(data => (data.id === participant.id && data.cueCard))

                                    if ((selectedCueCards && selectedCueCards.length === 0) || (selectedCueCards.length > 0 && !selectedStudent)) {
                                        return <p
                                            style={{
                                                fontSize: '16px',
                                                color: 'rgba(0, 0, 0, 0.30)',
                                                position: 'relative',
                                                backgroundColor: 'transparent'
                                            }}
                                        >
                                            Select a Cue Card
                                        </p>
                                    } else {
                                        return `Activity ${selectedStudent?.activityNumber} Student ${selectedStudent?.indexValue}`
                                    }
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: theme => ({
                                            borderRadius: '0px 0px 8px 8px',
                                            height: '192px',
                                            width: breakoutActive ? '256px' : '424px',
                                            border: '1px solid rgba(0, 0, 0, 0.14)',
                                            boxShadow: '0px 7px 20px 0px rgba(0, 0, 0, 0.06), 0px 3px 1px 0px rgba(0, 0, 0, 0.02)',
                                            padding: '0px 8px 8px 8px',
                                            '& .MuiMenu-list': {
                                                paddingTop: '16px'
                                            }
                                        })
                                    }

                                }}
                            >
                                {cueCardResponse ? cueCardResponse.map((activities, index) => (
                                    [
                                        <ListSubheader
                                            id='category'
                                            key={`activity-${activities.activityNumber}`}
                                            sx={{
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                lineHeight: '38px',
                                                padding: '8px'
                                            }}>
                                            Activity {activities.activityNumber}
                                        </ListSubheader>,
                                        ...activities.activityMedia.map(
                                            (data, mediaIndex) => (breakoutActive
                                                ? checkMediaAvailability(data.contentId)
                                                : true) ? (
                                                    <CustomMenuItem
                                                        data-testid={`activity-${activities.activityNumber}-media-${mediaIndex}`}
                                                        key={`activity-${activities.activityNumber}-media-${mediaIndex}`}
                                                        value={[activities as any, mediaIndex]}>
                                                        {activities.activityNumber} {'Student ' + numberToAlphabet(mediaIndex + 1)}
                                                    </CustomMenuItem>
                                                ) : null)

                                    ]
                                )) : 'null'}
                            </Select>

                            {studentNameList[participantIndex].isSelected ? (
                                <SlideItem
                                    data-testid='cueCardImage'
                                    breakoutActive={breakoutActive}
                                    src={selectedCueCards.length > 0 ? showCueCardImage(participant.id) : ''}
                                    alt='cue card image'
                                    sx={{ border: 'solid 1px rgba(0, 0, 0, 0.06)' }}
                                />
                            ) : (
                                <SlideItem
                                    data-testid='cue-card-thumbnail-grey'
                                    breakoutActive={breakoutActive}
                                    sx={{ backgroundColor: '#D9D9D9' }}
                                />
                            )}
                        </StudentSelectContainer>
                    </FormControl>
                ))}
            </SelectContainer>
        </>
    )
}

export { CueCardSelect }
