import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Action, CustomDialogComponent } from 'hello-design-system'

import { ConferenceContext } from 'contexts/conference'

export const DialogComponent = styled(CustomDialogComponent)(() => (
    {
        '& .MuiTypography-h6': {
            textTransform: 'unset !important',
            fontWeight: 700,
            fontSize: '18px'
        },
        '.MuiButton-containedPrimary': {
            borderRadius: '16px !important'
        },
        '.MuiButton-outlinedPrimary': {
            color: '#003359 !important',
            fontWeight: 700,
            fontSize: '15px'
        }
    }
))

export const StyledBox = styled(Box)({
    display: 'inline',
    fontWeight: 'bold'
})

export const StyledTypography = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    fontSize: '12px'
}))

const AbandonCueCardModal: React.FC<{ route: string; close: () => void }> = ({ close, route }) => {
    const navigate = useNavigate()
    const conference = useContext(ConferenceContext)

    const cueCardForStudent = conference.status === 'session-joined' ? conference.cueCard : []

    const actions: Action[] = [{
        label: 'Go Back',
        onClick: () => close(),
        variant: 'outlined',
        color: 'primary'
    }, {
        label: 'Dismiss Cue Cards',
        onClick: () => onShareCueCardsClick(),
        variant: 'contained',
        color: 'primary'
    }]

    const onShareCueCardsClick = (): void => {
        if (conference.status === 'session-joined' && cueCardForStudent) {
            const updatedCueCards = cueCardForStudent.map(data => {
                return {
                    id: data.id,
                    cueCard: null
                }
            })
            conference.setCueCardForStudent(updatedCueCards)
            conference.primus.send('cueCard:add', {
                cueCards: updatedCueCards
            })
        }
        navigate(route)
        close()
    }

    return (
        <>
            <DialogComponent
                open={true}
                onClose={() => close()}
                title='Dismiss Cue Cards ?'
                actions={actions}
                maxWidth={'sm'}
                showCloseIcon={true}
                fullScreen={false}
            >
                <StyledBox>
                    <StyledTypography
                        color='text.primary'
                        gutterBottom
                    >
                        Moving to another section will dismiss the cue cards for all the students. Do you want to dismiss cue cards and continue?
                    </StyledTypography>
                </StyledBox>
            </DialogComponent>
        </>
    )
}

export { AbandonCueCardModal }
