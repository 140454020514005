import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Action, CustomDialogComponent } from 'hello-design-system'
import { BreakoutRoomsState } from 'pages/useBreakouts'

export const DialogComponent = styled(CustomDialogComponent)(() => (
    {
        '& .MuiTypography-h6': {
            textTransform: 'unset !important',
            fontWeight: 700,
            fontSize: '18px'
        },
        '.MuiButton-containedPrimary': {
            borderRadius: '16px !important'
        },
        '.MuiButton-outlinedPrimary': {
            color: '#003359 !important',
            fontWeight: 700,
            fontSize: '15px'
        }
    }
))

export const StyledBox = styled(Box)({
    display: 'inline',
    fontWeight: 'bold'
})

export const StyledTypography = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
    fontSize: '12px'
}))

const ExitBORModal: React.FC<{ close: () => void; breakouts?: BreakoutRoomsState }> = ({ close, breakouts }) => {
    const actions: Action[] = [{
        label: 'Go Back',
        onClick: () => close(),
        variant: 'outlined',
        color: 'primary'
    }, {
        label: 'Exit Breakout Rooms',
        onClick: () => onShareCueCardsClick(),
        variant: 'contained',
        color: 'primary'
    }]

    const onShareCueCardsClick = (): void => {
        breakouts?.stop()
        close()
    }

    return (
        <>
            <DialogComponent
                open={true}
                onClose={() => close()}
                title='Exit Breakout Rooms?'
                actions={actions}
                maxWidth={'sm'}
                showCloseIcon={true}
                fullScreen={false}
            >
                <StyledBox>
                    <StyledTypography
                        color='text.primary'
                        gutterBottom
                    >
                        You are about to end the breakout rooms and return to class mode. Do you want to proceed and end breakout rooms for all the students?
                    </StyledTypography>
                </StyledBox>
            </DialogComponent>
        </>
    )
}

export { ExitBORModal }
