import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { styled } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

import { ConferenceContext } from 'contexts/conference'
import { RectangleButton } from 'components/buttons'

import { CueCardInfo } from 'api/classroom/ClassroomState'
import { BreakoutRoomsState } from 'pages/useBreakouts'

import { StepButton } from '../breakout-rooms/Buttons'
import { CueCardSelect } from './CueCardSelect'
import { CueCardBRModal } from './CueCardBR'

import RefreshIcon from '../refreshIcon.svg'
import PreviousIcon from '../PreviousIcon.svg'

export const numberToAlphabet = (number: number): string => {
    if (number >= 1 && number <= 26) {
        return String.fromCharCode(64 + number)
    } else {
        return 'Invalid input'
    }
}

export const sortingArray = (studentData: ParticipantData[]): ParticipantData[] => {
    const sortedStudentData = studentData.sort((a: { firstName: string }, b: { firstName: string }) =>
        a.firstName.localeCompare(b.firstName, 'en', { sensitivity: 'base' }))
    const uniqueStudentData = sortedStudentData.filter(
        (student, index, sortedStudentArray) => index === sortedStudentArray.findIndex(sortedStudent => sortedStudent.id === student.id)
    )
    return uniqueStudentData
}

const StepButtonsContainer = styled('div')<{ BRSuggest: boolean }>(({ theme, BRSuggest }) => ({
    display: 'flex',
    justifyContent: BRSuggest ? 'space-between' : 'flex-end',
    margin: ' 40px 0px 0px 0px',
    gap: '21px',
    button: {
        marginLeft: theme.spacing(1),
        fontWeight: 600
    }
}))

const StepButtonsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    button: {
        marginLeft: theme.spacing(1),
        fontWeight: 600
    }
}))

const RectangleButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    position: 'relative'
}))

export type ParticipantData =
{
    id: string
    firstName: string
    lastName: string
    isSelected?: boolean
}

type SelectedDataModal = [CueCardInfo, number]

type CueCardItem = {
    id: string
    cueCard: {
        contentId: string
        imageURL: string
    } | null
    activityNumber?: string
    indexValue?: string
    mediaIndex?: number
}

type ActivityData = {
    activityNumber: string
    index: number
}

export type CueCardItemArray = CueCardItem[]

const CueCardsModal: React.FC<{
    forCC?: boolean
    breakouts?: BreakoutRoomsState
    close: () => void
    stepBack?: () => void
}> = ({ close, breakouts, stepBack, forCC = false }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const conference = useContext(ConferenceContext)

    const cueCardResponse = conference.status === 'session-joined' && conference.cueCardInfo

    const cueCardForStudent = conference.status === 'session-joined' && conference.cueCard

    // function to get the student list without teacher
    const studentList = (): ParticipantData[] => sortingArray(
        conference.participants.filter(p => p.role !== 'teacher').map(data => ({
            id: data.wseUserId,
            firstName: data.firstName,
            lastName: data.lastName
        }))
    )

    const [studentNameList, setStudentNameList] = useState<ParticipantData[]>(studentList())

    const [selectedCueCards, setSelectedCueCards] = useState<CueCardItemArray>([])
    const [isCueCardSelected, setIsCueCardSelected] = useState<boolean>(true)
    const [isCueCardChanged, setIsCueCardChanged] = useState<boolean>(false)

    const [isCueCardEdit, setIsCueCardEdit] = useState<boolean>(false)

    const isCueCardPage = location.pathname === '/teacher/classroom/cue-cards'

    // Function to handle the data based on selection and unselection
    const menuItemHandlerNew = (selectedStatus: boolean, participant: ParticipantData, selectedValues?: SelectedDataModal | null): void => {
        if (!cueCardForStudent) return

        if (cueCardForStudent) {
            const selectedCueCardsArray = selectedCueCards.length > 0 ? selectedCueCards : cueCardForStudent

            const updatedStudents = selectedCueCardsArray.map(student => {
                if (student.id === participant.id) {
                    if (selectedStatus && selectedValues) {
                        const mediaIndex = selectedValues[1]
                        return {
                            ...student,
                            cueCard: {
                                contentId: selectedValues[0].activityMedia[mediaIndex].contentId,
                                imageURL: selectedValues[0].activityMedia[mediaIndex].path
                            },
                            activityNumber: selectedValues[0].activityNumber,
                            indexValue: numberToAlphabet(mediaIndex + 1)
                        }
                    } else {
                        return {
                            id: participant.id,
                            cueCard: null
                        }
                    }
                }

                return student
            })

            const updateStudentSelection = studentNameList.map(user =>
                user.id === participant.id ? { ...user, isSelected: !!selectedStatus } : user
            )

            setStudentNameList(updateStudentSelection)
            setSelectedCueCards(updatedStudents)
        }

    }

    // Function called while selecting the dropdown option
    const handleChange = (event: SelectChangeEvent<''>, participant: ParticipantData): void => {
        const selectedValues = event.target.value as unknown as SelectedDataModal
        menuItemHandlerNew(true, participant, selectedValues)
        setIsCueCardChanged(true)
    }

    // Function called while clicking cross icon for the selected value
    const clearCueCard = (participant: ParticipantData): void => {
        menuItemHandlerNew(false, participant, null)
        setIsCueCardChanged(true)
    }

    // Function called while clicking clear all selection button at bottom
    const clearAllCueCard = (): void => {
        setStudentNameList(studentList())
        const newSelectedCueCardArray = selectedCueCards.map(data => {
            return {
                id: data.id,
                cueCard: null
            }
        })
        setSelectedCueCards(newSelectedCueCardArray)
    }

    // Function called while share cue cards button at bottom
    const onShareCueCardsClick = (): void => {
        if (forCC) {
            breakouts?.setRoomActive()
        }
        navigate('/teacher/classroom/cue-cards')
        close()

        if (conference.status !== 'session-joined' || selectedCueCards.length === 0) return

        // If the student left the session and teacher shares the cue card for others, then if the left out student enters again
        // he should not see the cue card
        const updatedCueCards = selectedCueCards.map(cueCard => {
            const matchingStudent = studentNameList.find(student => student.id === cueCard.id)
            return matchingStudent ? {
                ...cueCard,
                cueCard: cueCard.id === matchingStudent.id ? cueCard.cueCard : null
            } : {
                id: cueCard.id,
                cueCard: null
            }
        })

        conference.setCueCardForStudent(updatedCueCards)
        conference.primus.send('cueCard:add', { cueCards: updatedCueCards })
    }

    // Function used to show the cue card image thumbail for the selected students
    const showCueCardImage = (participantId: string): string => {
        const cueCardImage = selectedCueCards ? selectedCueCards.find(data => data.id === participantId) : null
        return cueCardImage?.cueCard?.imageURL ?? ''
    }

    // useEffect to update the student list when new student added or removed
    useEffect(() => {
        const updatedStudentList = studentList()

        if (updatedStudentList.length > studentNameList.length) {
            updatedStudentList.forEach((updatedstudent: ParticipantData) => {
                const filteredStudentNameList = studentNameList.find(student => student.id === updatedstudent.id)
                if (!filteredStudentNameList) {
                    studentNameList.push({
                        id: updatedstudent.id,
                        firstName: updatedstudent.firstName,
                        lastName: updatedstudent.lastName
                    })
                }
            })

        } else {
            studentNameList.forEach((student, index) => {
                const filteredUpdatedStudentList = updatedStudentList.find((updatedstudent: { id: string }) => updatedstudent.id === student.id)
                if (!filteredUpdatedStudentList) {
                    studentNameList.splice(index, 1)
                }
            })

        }

        setStudentNameList(sortingArray(studentNameList))
    }, [conference.participants])

    // useEffect for make the share cue card button enable if any one selection done
    useEffect(() => {
        const isCueCardSaved = cueCardForStudent ? cueCardForStudent.find(data => data.cueCard) : false

        const selectedStudentNameList = studentNameList.find(data => data.isSelected)

        if (isCueCardSaved) {
            setIsCueCardSelected(true)
            if (isCueCardChanged || !selectedStudentNameList || !isCueCardPage) {
                setIsCueCardEdit(true)
                setIsCueCardSelected(false)
                if (!isCueCardPage && !selectedStudentNameList) {
                    setIsCueCardSelected(true)
                }
            }
        } else {
            setIsCueCardSelected(!selectedStudentNameList)
        }
    }, [studentNameList])

    useEffect(() => {

        const isCueCardAvailable = cueCardForStudent ? cueCardForStudent.find(data => data.cueCard) : false

        if (isCueCardAvailable && cueCardForStudent) {

            // once after refresh set the activity number and index from the cue card saved data
            const updatedCueCardForStudent = cueCardForStudent.map(student => {
                let matchingActivity: ActivityData | undefined

                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                cueCardResponse ? cueCardResponse.forEach(activity => {
                    if (student.cueCard?.contentId) {
                        const filteredActivity = activity.activityMedia.find((media: { contentId: string }) => media.contentId === student.cueCard?.contentId)
                        if (filteredActivity) {
                            matchingActivity = {
                                activityNumber: activity.activityNumber,
                                index: activity.activityMedia.indexOf(filteredActivity)
                            }
                        }
                    }
                }) : null

                if (matchingActivity) {
                    return {
                        ...student,
                        activityNumber: matchingActivity.activityNumber,
                        mediaIndex: matchingActivity.index,
                        indexValue: numberToAlphabet(matchingActivity.index + 1)
                    }
                } else {
                    return student
                }
            })

            setSelectedCueCards(updatedCueCardForStudent)
        }

        if (isCueCardAvailable && cueCardForStudent) {

            const cueCardUsers: ParticipantData[] = []

            cueCardForStudent.forEach(data => {

                const filters = studentNameList.find(user =>
                    user.id === data.id
                )

                if (!filters) return

                cueCardUsers.push(
                    { ...filters, isSelected: !!data.cueCard }
                )
            })

            setStudentNameList(cueCardUsers)
        }
    }, [])

    const CueCardComponent = (forCC || breakouts?.isActive) ? CueCardBRModal : CueCardSelect

    return (
        <>
            <CueCardComponent
                breakouts={breakouts as BreakoutRoomsState}
                studentNameList={studentNameList}
                handleChange={handleChange}
                selectedCueCards={selectedCueCards}
                cueCardResponse={cueCardResponse}
                clearCueCard={clearCueCard}
                showCueCardImage={showCueCardImage}
                breakoutActive={breakouts?.isActive || false}
            />
            <StepButtonsContainer BRSuggest={forCC}>
                {forCC && (
                    <RectangleButtonContainer>
                        <RectangleButton
                            id='cuecard-modal-clear-button'
                            text='Previous'
                            color='inherit'
                            onClick={stepBack}
                            iconFrontSrc={PreviousIcon}
                            customColor='#F12C3E'
                        />
                    </RectangleButtonContainer>
                )}
                <StepButtonsWrapper>
                    <RectangleButtonContainer>
                        <RectangleButton
                            id='cuecard-modal-clear-button'
                            text='Clear all selection'
                            color='inherit'
                            onClick={clearAllCueCard}
                            iconSrc={RefreshIcon}
                            customColor='#F12C3E'
                        />
                    </RectangleButtonContainer>
                    <StepButton
                        id='cuecard-modal-share-button'
                        text={(isCueCardEdit && isCueCardPage) ? 'Update Cue Cards' : 'Share Cue Cards'}
                        disabled={isCueCardSelected}
                        onClick={onShareCueCardsClick}
                    />
                </StepButtonsWrapper>
            </StepButtonsContainer>
        </>
    )
}

export { CueCardsModal }
