import { useContext, useState, useEffect } from 'react'
// import { clamp } from 'lodash'
import { ConferenceContext } from 'contexts/conference'
import { BreakoutRoomsState } from 'pages/useBreakouts'
import { CloseButton } from './Buttons'
// import { SettingsModalStep } from './SettingsModalStep'
import { RoomsModalStep } from './RoomsModalStep'
import { Wrapper, InnerWrapper, CloseButtonWrapper } from 'components/CustomModal'
import { CueCardsModal } from '../cue-cards/CueCardsModal'
import { styled } from '@mui/system'
import { useLocation } from 'react-router-dom'

export type RoomAssignment = {
    wseProfilePhotoUrl?: string
    firstName: string
    lastName: string
    roomId?: number
    id: string
    indexId: number
}

const DialogInnerWrapper = styled(InnerWrapper)(({ theme }) => ({
    maxWidth: '600px !important'
}))

const getMode = (pathname: string): 'speak' | 'cue-cards' | 'slides' | 'whiteboard' => {
    if (pathname.includes('/teacher/classroom/cue-cards')) return 'cue-cards'
    if (pathname.includes('/teacher/classroom/slides')) return 'slides'
    if (pathname.includes('/teacher/classroom/whiteboard')) return 'whiteboard'
    return 'speak'
}

const BreakoutRoomsModal: React.FC<{ forCC: boolean; breakouts: BreakoutRoomsState; close: () => void }> = ({ breakouts, close, forCC = false }) => {
    const conference = useContext(ConferenceContext)
    const { pathname } = useLocation()

    const [roomsCount] = useState<number>(4)
    const [borDuration] = useState<number>(5)
    const [borMode] = useState<'speak' | 'cue-cards' | 'slides' | 'whiteboard'>(getMode(pathname))
    const [step, setStep] = useState<number>(1)
    const [roomA, setRoomA] = useState<RoomAssignment[]>([])
    const [roomB, setRoomB] = useState<RoomAssignment[]>([])
    const [roomC, setRoomC] = useState<RoomAssignment[]>([])
    const [roomD, setRoomD] = useState<RoomAssignment[]>([])
    const [roomsEmpty, setRoomsEmpty] = useState<boolean>(false)
    const [roomsFull, setRoomsFull] = useState<boolean>(false)

    const handleBorStart = (): void => {
        close()

        const usersRooms = [roomA, roomB, roomC, roomD].flatMap((room, roomId) =>
            room.map(s => ({ userId: s.id, roomId, indexId: s.indexId }))
        )

        breakouts.start({
            mode: borMode,
            duration: borDuration,
            usersRooms
        })
    }

    const getRoom = (index: number): RoomAssignment[] => {
        const rooms = [roomA, roomB, roomC, roomD]
        return rooms[index] || []
    }

    const setRooms = (index: number, students: RoomAssignment[]): void => {
        const setRoomFunctions = [setRoomA, setRoomB, setRoomC, setRoomD]
        if (setRoomFunctions[index]) {
            setRoomFunctions[index](students.map((s, indexId) => ({ ...s, roomId: index, indexId })))
        }
    }

    const moveStep = (): void => {
        const usersRooms = [roomA, roomB, roomC, roomD].flatMap((room, roomId) =>
            room.map(s => ({ userId: s.id, roomId, indexId: s.indexId }))
        )

        breakouts.setRoomData({
            mode: forCC ? 'cue-cards' : borMode,
            duration: borDuration,
            usersRooms
        })
        setStep(2)
    }

    useEffect(() => {
        const students = new Map(conference.participants.filter(p => p.role !== 'teacher').map(student => [
            student.wseUserId,
            {
                wseProfilePhotoUrl: student.wseProfilePhotoUrl,
                firstName: student.firstName,
                lastName: student.lastName,
                id: student.wseUserId,
                indexId: 0
            }
        ]))

        const assignments: RoomAssignment[] = Array.from(students.values())
        const initialAssignments: RoomAssignment[] = [...assignments]
        let studentsPushed = 0
        let chunkSizeDecreased = false

        Array.from(Array(4)).forEach((_, i) => {
            const chunkSize = Math.ceil(assignments.length / roomsCount)
            let studentsToPush = chunkSize
            if (!chunkSizeDecreased && studentsPushed > chunkSize) {
                chunkSizeDecreased = true
                if (initialAssignments.length - studentsPushed > (roomsCount - (i + 1)) * (i + 1)) {
                    studentsToPush = chunkSize + 1
                }
            }
            studentsToPush += roomsCount === i + 1 ? 1 : 0
            studentsPushed += studentsToPush
            setRooms(i, assignments.splice(0, studentsToPush))
        })
    }, [conference.participants, roomsCount])

    useEffect(() => {
        setRoomsEmpty(!roomA.length || !roomB.length || (roomsCount > 2 && !roomC.length) || (roomsCount > 3 && !roomD.length))
        setRoomsFull(roomA.length > 4 || roomB.length > 4 || (roomsCount > 2 && roomC.length > 4) || (roomsCount > 3 && roomD.length > 4))
    }, [roomA, roomB, roomC, roomD, roomsCount])

    const renderBRModal = (): JSX.Element => forCC && step === 2 ? renderCCModal() : renderBRRoom()

    const renderCCModal = (): JSX.Element => (
        <CueCardsModal
            forCC={forCC}
            breakouts={breakouts}
            stepBack={() => setStep(1)}
            close={close}
        />
    )

    const renderBRRoom = (): JSX.Element => (
        <RoomsModalStep
            roomsCount={roomsCount}
            getRoom={getRoom}
            setRooms={setRooms}
            startBor={handleBorStart}
            roomsEmpty={roomsEmpty}
            roomsFull={roomsFull}
            forCC={forCC}
            moveStep={moveStep}
        />
    )

    const modalHeading = forCC && step === 2 ? 'Share Cue Cards in Breakout Rooms' : 'Breakout Rooms'

    return (
        <Wrapper>
            <DialogInnerWrapper id='bor-modal-inner-wrapper'>
                <CloseButtonWrapper><CloseButton id='bor-modal-close-button' onClick={close} /></CloseButtonWrapper>
                <h4>{modalHeading}</h4>
                {renderBRModal()}
            </DialogInnerWrapper>
        </Wrapper>
    )
}

export { BreakoutRoomsModal }
