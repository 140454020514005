import { WhiteboardIcon, WhiteboardActiveIcon } from 'icons'
import { NavButton } from './NavButton'

const WhiteboardButton: React.FC<{ id: string; disabled?: boolean; handleOnClick?: (data: string) => void }> = ({ id, disabled, handleOnClick }) => (
    <NavButton
        id={id}
        text='Whiteboard'
        path='/teacher/classroom/whiteboard'
        icon={WhiteboardIcon}
        activeIcon={WhiteboardActiveIcon}
        disabled={disabled}
        onClick={() => {
            if (handleOnClick) {
                handleOnClick('/teacher/classroom/whiteboard')
            }
        }}
    />
)

export { WhiteboardButton }
