import { Dispatch, useContext, useEffect, useState } from 'react'
import { uniq } from 'lodash'
import { ConferenceContext } from 'contexts/conference'
import { BookingContext } from 'contexts/booking'
import { BreakoutAddUserEvent, BreakoutRemoveUserEvent, BreakoutSwapUserEvent, BreakoutTeacherLinkEvent } from 'primus/types/events'
import { BreakoutRoom } from 'api/classroom/ClassroomState'
import { WseUserId } from 'api/types'
import { ConferenceParticipant } from 'contexts/conference/types'

type BreakoutRoomsState = {
    rooms: BreakoutRoom[]
    isActive: boolean
    duration?: number
    startTime?: number
    teacherRoom: number | null
    mode: BorMode

    start: (props: BreakoutsProps) => void
    setRoomData: (props: BreakoutsProps) => void
    stop: () => void
    setRoomActive: () => void
    setDuration: Dispatch<React.SetStateAction<number>>
    linkTeacher: (roomId: RoomId | null) => void
    getCurrentRoom: () => RoomId | null
}
type BreakoutsProps = {
    usersRooms: Array<{ userId: WseUserId; roomId: number; indexId: number }>
    mode: BorMode
    duration: number
}
type RoomId = 0 | 1 | 2 | 3
type BorMode = 'speak' | 'cue-cards' | 'slides' | 'whiteboard'

const useBreakouts = (): BreakoutRoomsState => {
    const conference = useContext(ConferenceContext)
    const booking = useContext(BookingContext)

    const isTeacher = booking.status === 'validated' && booking.user.role === 'teacher'

    const [rooms, setRooms] = useState<BreakoutRoom[]>([])
    const [isActive, setIsActive] = useState<boolean>(false)
    const [duration, setDuration] = useState<number>(5)
    const [teacherRoom, setTeacherRoom] = useState<number | null>(null)
    const [mode, setMode] = useState<BorMode>('speak')
    const [startTime, setStartTime] = useState<number>()
    const start = (props: BreakoutsProps): void => {
        if (conference.status === 'session-joined') {
            if (isActive) {
                conference.primus.send('breakout:stop', {})
                setRooms(rooms => rooms.map(room => ({ ...room, users: [] })))
            }
            setIsActive(true)
            setMode(props.mode)
            setDuration(props.duration)
            linkTeacher(null)
            setStartTime(Date.now())
            conference.primus.send('breakout:start', { timeSlot: props.duration })
            props.usersRooms.forEach(({ roomId, userId, indexId }) => addRoomParticipant(roomId, userId, true, indexId))
        }
    }

    const setRoomData = (props: BreakoutsProps): void => {
        if (conference.status === 'session-joined') {
            if (isActive) {
                conference.primus.send('breakout:stop', {})
                setRooms(rooms => rooms.map(room => ({ ...room, users: [] })))
            }
            setMode(props.mode)
            setDuration(props.duration)
            linkTeacher(null)
            setStartTime(Date.now())
            conference.primus.send('breakout:start', { timeSlot: props.duration })
            props.usersRooms.forEach(({ roomId, userId, indexId }) => addRoomParticipant(roomId, userId, true, indexId))
        }
    }

    const setRoomActive = (): void => {
        if (conference.status === 'session-joined') {
            setIsActive(true)
        }
    }

    const stop = (): void => {
        if (conference.status === 'session-joined') {
            setStartTime(void 0)
            setIsActive(false)
            conference.primus.send('breakout:stop', {})
        }
    }
    const linkTeacher = (room: RoomId | null): void => {
        if (conference.status === 'session-joined') {
            setTeacherRoom(room)
            if (isTeacher) {
                conference.primus.send('breakout:teacher-link', { room })
            }
        }
    }
    const getCurrentRoom = (): RoomId | null => {
        if (booking.status === 'validated') {
            return rooms.find(r => r.users.some(u => u === booking.user.id))?.id ?? null
        } else return null
    }

    // PRIVATE
    const addRoomParticipant = (roomId: number, userId: WseUserId, publish: boolean = false, userIndex: number | undefined = undefined): void => {
        if (conference.status === 'session-joined') {
            setRooms(rooms => rooms.map(room =>
                roomId === room.id ? { ...room, users: uniq(room.users.concat(userId)) } : room
            ))

            if (publish) {
                conference.primus.send('breakout:add-user', {
                    user: userId,
                    room: roomId,
                    index: userIndex,
                    noCheck: false
                })
            }
        }
    }

    const removeRoomParticipant = (roomId: number, userId: WseUserId, publish: boolean = false): void => {
        if (conference.status === 'session-joined') {
            setRooms(rooms => rooms.map(room =>
                roomId === room.id ? { ...room, users: room.users.filter(u => u !== userId) } : room
            ))

            if (publish) {
                conference.primus.send('breakout:remove-user', {
                    user: userId,
                    room: roomId,
                    noCheck: false
                })
            }
        }
    }

    const getLowestUsersRoomId = (rooms: BreakoutRoom[], participants: ConferenceParticipant[]): number => {
        const roomsWithParticipants = rooms
            .map(room => ({
                id: room.id,
                participants: participants.filter(p => room.users.some(u => u === p.wseUserId))
            }))

        return roomsWithParticipants
            .filter(r => r.participants.length > 0)
            .reduce(
                (result, current) => current.participants.length < result.participants.length ? current : result,
                roomsWithParticipants[0]
            ).id

    }

    const rejoinRoom = (rooms: BreakoutRoom[]): void => {
        if (conference.status === 'session-joined' && booking.status === 'validated') {
            const { id: userId, previousBreakoutRoom } = booking.user

            const roomId = previousBreakoutRoom !== null
                ? previousBreakoutRoom
                : getLowestUsersRoomId(rooms, conference.participants)

            removeRoomParticipant(roomId, userId, true)
            addRoomParticipant(roomId, userId, true)
        }
    }

    useEffect(() => {
        if (!isActive) {
            setDuration(5)
            setRooms(rooms => rooms.map(room => ({ ...room, users: [] })))
        }
    }, [isActive])

    // On start
    useEffect(() => {
        if (conference.status === 'session-joined' && booking.status === 'validated') {
            const { breakouts } = conference.classroomStateOnJoin.dcAppData.wbData

            setIsActive(breakouts.isActive)
            setRooms(breakouts.rooms)
            setDuration(breakouts.timeSlot)
            setTeacherRoom(breakouts.teacherRoom)

            if (breakouts.startTime && breakouts.timeSlot) {
                setStartTime(breakouts.startTime)
            }

            // Rejoin room on students page reload
            if (breakouts.isActive && booking.user.role === 'student') {
                rejoinRoom(breakouts.rooms)
            }

            const onStart = (): void => setIsActive(true)
            const onStop = (): void => setIsActive(false)

            const onUserAdded = (eventData: BreakoutAddUserEvent): void => {
                addRoomParticipant(eventData.room, eventData.user)
            }
            const onUserRemoved = (eventData: BreakoutRemoveUserEvent): void => removeRoomParticipant(eventData.room, eventData.user)
            const onUserSwapped = (eventData: BreakoutSwapUserEvent): void => {
                removeRoomParticipant(eventData.fromRoom, eventData.user)
                addRoomParticipant(eventData.toRoom, eventData.user)
            }
            const onTeacherChangedChannel = (eventData: BreakoutTeacherLinkEvent): void => {
                setTeacherRoom(eventData.room)
            }

            conference.primus.on('breakout:start', onStart)
            conference.primus.on('breakout:stop', onStop)
            conference.primus.on('breakout:add-user', onUserAdded)
            conference.primus.on('breakout:remove-user', onUserRemoved)
            conference.primus.on('breakout:swap-user', onUserSwapped)
            conference.primus.on('breakout:teacher-link', onTeacherChangedChannel)
        }
    }, [conference.status])

    return {
        rooms,
        isActive,
        duration,
        teacherRoom,
        mode,
        startTime,
        start,
        stop,
        setDuration,
        linkTeacher,
        getCurrentRoom,
        setRoomData,
        setRoomActive
    }
}

export { useBreakouts }
export type { BreakoutRoomsState }
