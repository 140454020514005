import { styled } from '@mui/material'

import { Wrapper, InnerWrapper } from 'components/CustomModal'

import { BreakoutRoomsState } from 'pages/useBreakouts'

import CloseBtn from '../closeIcon.svg'
import { CueCardsModal } from './CueCardsModal'

const InnerContainer = styled(InnerWrapper)<{ breakoutActive: boolean }>(({ breakoutActive }) => ({
    width: breakoutActive ? '600px !important' : '90%',
    maxWidth: '600px !important',
    padding: breakoutActive ? '24px' : '40px',
    maxHeight: '800px !important'
}))

const ShareCueCardTitle = styled('div')(() => ({
    fontSize: '18px',
    fontWeight: 700
}))

const CueCardCloseButton = styled('img')(() => ({
    cursor: 'pointer'
}))

export type ParticipantData =
{
    id: string
    firstName: string
    lastName: string
    isSelected?: boolean
}

type CueCardItem = {
    id: string
    cueCard: {
        contentId: string
        imageURL: string
    } | null
    activityNumber?: string
    indexValue?: string
    mediaIndex?: number
}

export type CueCardItemArray = CueCardItem[]

const ShareCueCardsModal: React.FC<{ forCC?: boolean; breakouts?: BreakoutRoomsState; close: () => void }> = ({ close, breakouts, forCC = false }) => {

    const renderCueCardModal = (): JSX.Element => (
        <Wrapper>
            <InnerContainer breakoutActive={(forCC || breakouts?.isActive) || false} id='cueCard-modal-inner-wrapper'>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '10px',
                    marginBottom: '34px'
                }}>
                    <ShareCueCardTitle>Share Cue Cards</ShareCueCardTitle>
                    <CueCardCloseButton
                        id='cueCard-modal-close-button'
                        src={CloseBtn}
                        alt='cueCardCloseButton'
                        onClick={close}
                    />
                </div>
                <CueCardsModal close={close} breakouts={breakouts} forCC={forCC} />
            </InnerContainer>
        </Wrapper>
    )

    return (
        renderCueCardModal()
    )
}

export { ShareCueCardsModal }
