import { styled } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

import { CueCardInfo } from 'api/classroom/ClassroomState'
import { BreakoutRoomsState } from 'pages/useBreakouts'

import { CueCardSelect } from './CueCardSelect'
import { CueCardItemArray, ParticipantData } from './ShareCueCardsModal'

const RoomModule = styled('div')(({ theme }) => ({
    borderRadius: theme.spacing(2),
    border: `solid 1px ${theme.palette.neutral.hover}`,
    overflow: 'hidden',
    margin: `${theme.spacing(2)} 0`,
    p: {
        width: '100%',
        backgroundColor: theme.palette.neutral.darker,
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        fontWeight: '600',
        span: {
            color: theme.palette.error.main,
            fontSize: theme.spacing(1.75),
            fontWeight: 400,
            marginLeft: theme.spacing(2)
        }
    },
    '> div': {
        padding: theme.spacing(1)
    }
}))

const Student = styled('div')(({ theme }) => ({
    backgroundColor: 'transparent',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    img: {
        cursor: 'grab'
    },
    '> span': {
        fontWeight: '600'
    },
    '&:last-of-type': {
        margin: 0
    }
}))

const CueCardBRModal: React.FC<{
    breakouts: BreakoutRoomsState
    studentNameList: ParticipantData[]
    handleChange: (event: SelectChangeEvent<''>, participant: ParticipantData) => void
    selectedCueCards: CueCardItemArray
    cueCardResponse: false | CueCardInfo[] | null
    clearCueCard: (participant: ParticipantData) => void
    showCueCardImage: (participantId: string) => string
}> = ({ breakouts, studentNameList, handleChange, selectedCueCards, cueCardResponse, clearCueCard, showCueCardImage }) => {
    return (
        <div id='bor-modal-rooms-wrapper'>
            {breakouts.rooms.map((data, i) => {
                if (data.users.length) {
                    const roomStudents = studentNameList.filter(user => data.users.includes(user.id))
                    return (
                        <RoomModule id='bor-modal-room-module' key={`Room ${String.fromCharCode('A'.charCodeAt(0) + i)}`}>
                            <p>
                                {`Room ${String.fromCharCode('A'.charCodeAt(0) + i)}`}
                            </p>
                            <div>
                                <Student id='bor-modal-student-drag'>
                                    <CueCardSelect
                                        studentNameList={roomStudents}
                                        handleChange={handleChange}
                                        selectedCueCards={selectedCueCards}
                                        cueCardResponse={cueCardResponse}
                                        clearCueCard={clearCueCard}
                                        showCueCardImage={showCueCardImage}
                                        breakout={breakouts}
                                        breakoutActive
                                    />
                                </Student>
                            </div>
                        </RoomModule>
                    )
                } else {
                    return null
                }
            })}
        </div>
    )
}

export { CueCardBRModal }
